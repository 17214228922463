import * as React from 'react';

const HelpSvg = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      viewBox='0 0 20 20'
      style={{ transition: 'fill 0.3s ease' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <circle cx={10} cy={10} r={10} fill={isHovered ? '#979797' : '#fff'} /> {/* Circle color change on hover */}
      <path
        fill={isHovered ? '#ffffff' : '#1C1F26'} // Text color change on hover
        d='M9.944 17.145a.994.994 0 0 1-.525-.149.897.897 0 0 1-.348-.395.826.826 0 0 1-.054-.51.865.865 0 0 1 .258-.45.968.968 0 0 1 .484-.242c.184-.033.374-.016.546.05.173.067.32.18.424.325a.84.84 0 0 1 .16.49.864.864 0 0 1-.28.62.993.993 0 0 1-.665.26ZM11.657 11a1.433 1.433 0 0 0-.572.522c-.136.22-.204.47-.196.724v.963c0 .234-.1.458-.277.623a.98.98 0 0 1-.668.258.981.981 0 0 1-.669-.258A.852.852 0 0 1 9 13.21v-.963a2.977 2.977 0 0 1 .483-1.667c.325-.503.795-.91 1.356-1.176.33-.15.612-.378.815-.663a1.808 1.808 0 0 0 .115-1.941 1.973 1.973 0 0 0-.732-.742 2.174 2.174 0 0 0-2.084-.055 1.996 1.996 0 0 0-.776.702c-.187.294-.285.63-.286.972 0 .233-.1.457-.277.623a.98.98 0 0 1-.668.258.98.98 0 0 1-.668-.258A.852.852 0 0 1 6 7.676c.002-.538.13-1.07.376-1.556A3.734 3.734 0 0 1 7.42 4.857a4.065 4.065 0 0 1 1.52-.733 4.235 4.235 0 0 1 1.708-.067c.793.14 1.522.5 2.09 1.036.567.535.945 1.219 1.085 1.96a3.436 3.436 0 0 1-.368 2.315A3.807 3.807 0 0 1 11.657 11Z'
      />
    </svg>
  );
};

export default HelpSvg;
